import { authApi } from './axios';

const login = async (data) => authApi.post('/auth/login', data);

const register = async (data) => authApi.post('/auth/signup', data);

const refresh = (data) => authApi.post('/auth/refresh-tokens', data);

const logout = (data) => authApi.post('/auth/revoke-tokens', data);

const forgotPassword = (data) => authApi.post('/auth/forgot-password', data);

const resetPassword = (data) => authApi.post('/auth/confirm-reset-password', data);

const resendVerification = (data) => authApi.post('/auth/resend-verification', data);

const auth = {
  login,
  register,
  refresh,
  logout,
  forgotPassword,
  resetPassword,
  resendVerification
};

export default auth;
