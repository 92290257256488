import { coachApi } from "../axios";
import { userApi } from "../axios";

export const list = async () => {
  const { data } = await coachApi.get("coaching/swimmers");
  return data;
};

export const get = async (swimmerId) => {
  const { data } = await coachApi.get(`coaching/swimmers/${swimmerId}`);
  return data;
};

const getCoachSwims = async (id, page = 0) => {
  const { data } = await userApi.get(`activities?page=${page}&swimmerId=${id}`);
  return data;
};

export const activities = {
  list: getCoachSwims,
};
