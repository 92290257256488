import axios from 'axios';
import auth from './auth';
import { setSession } from '../auth/utils';

// export const getToken = async () => {
//   const token = localStorage.getItem("id_token");
//   const tokenExpire = localStorage.getItem("token_expire");
//   if (!!token && !!tokenExpire && tokenExpire > Date.now()) {
//     return token;
//   }
//
//   const refreshToken = localStorage.getItem("refresh_token");
//   if (refreshToken) {
//     try {
//       let url = process.env.REACT_APP_V2_API_URL + "auth/refresh";
//       if (process.env.REACT_APP_STAGE === "STAGING") {
//         url += "?stage=staging";
//       }
//       const response = await axios.post(url, {
//         refreshToken,
//       });
//       const { data } = response;
//       localStorage.setItem("id_token", data.id_token);
//       localStorage.setItem(
//         "token_expire",
//         (data.expires_in - 60) * 1000 + Date.now()
//       );
//       if (data.refresh_token) {
//         localStorage.setItem("refresh_token", data.refresh_token);
//       }
//       return data.id_token;
//     } catch (err) {
//       console.log("REFRESH TOKEN ERROR: ", err.response);
//       alert("Your session has expired. Please logout and login again.");
//       localStorage.clear();
//     }
//   }
// };

const getAccessToken = async () => {
  const refreshToken = localStorage.getItem('refresh_token');

  if (!refreshToken) {
    return {};
  }

  try {
    const response = await auth.refresh({
      refreshToken
    });

    const { AccessToken, TokenType } = response.data;

    if (!AccessToken) {
      setSession(null);
    }

    localStorage.setItem('accessToken', AccessToken);

    return { AccessToken, TokenType };
  } catch (error) {
    setSession(null);
  }
};

export const publicApi = axios.create({
  baseURL: process.env.REACT_APP_V2_API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

const authApiInstance = axios.create({
  baseURL: process.env.REACT_APP_V2_API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

const userApiInstance = axios.create({
  baseURL: process.env.REACT_APP_V2_API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

const coachApiInstance = axios.create({
  baseURL: process.env.REACT_APP_COACH_API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

const adminApiInstance = axios.create({
  baseURL: process.env.REACT_APP_ADMIN_API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

const authRequestInterceptor = async (config) => {
  const accessToken = localStorage.getItem('accessToken');
  const tokenType = localStorage.getItem('tokenType');

  if (accessToken) {
    config.headers = {
      ...config.headers,
      authorization: `${tokenType ?? 'Bearer'} ${accessToken}`
    };
  }

  return config;
};

const authResponseInterceptor = async (error) => {
  const config = error?.config;

  if (error?.response?.status === 401 && !config?.sent) {
    config.sent = true;

    const result = await getAccessToken();

    if (result?.AccessToken) {
      config.headers = {
        ...config.headers,
        authorization: `${result?.TokenType ?? 'Bearer'} ${result?.AccessToken}`
      };
    }

    return axios(config);
  }
  return Promise.reject(error);
};

userApiInstance.interceptors.request.use(authRequestInterceptor, (error) => Promise.reject(error));
coachApiInstance.interceptors.request.use(authRequestInterceptor, (error) => Promise.reject(error));
adminApiInstance.interceptors.request.use(authRequestInterceptor, (error) => Promise.reject(error));

userApiInstance.interceptors.response.use((response) => response, authResponseInterceptor);
coachApiInstance.interceptors.response.use((response) => response, authResponseInterceptor);
adminApiInstance.interceptors.response.use((response) => response, authResponseInterceptor);

export const authApi = authApiInstance;
export const userApi = userApiInstance;
export const coachApi = coachApiInstance;
export const adminApi = adminApiInstance;
